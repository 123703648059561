import React from "react";
// Botón para subir la pantalla
import ButtonArrow from "../ButtonArrow";
import GroupButton from "../GroupButtons.jsx";
import { Container, Grid, keyframes, styled, Typography } from "@mui/material";

// Imágenes para el inicio
import Descarga from "../../img/sliderProducts/Aplicacion/descargaLetras.webp";
import NuestraApp from "../../img/sliderProducts/Aplicacion/nuestraApp.webp";

// Imagenes para las redirecciones
import Ios from "../../img/sliderProducts/Aplicacion/appStore.webp";
import Android from "../../img/sliderProducts/Aplicacion/googlePlay.webp";

// Estilo para las imágenes con efecto hover
const Image = styled("img")(({ theme }) => ({
  width: "80%",
  height: "auto",
  transition: "transform 0.3s ease",
  maxWidth: "200%", // Limita el ancho máximo de la imagen
  "&:hover": {
    transform: "scale(1.05)", // Efecto de zoom
  },
}));

// Animación de palpitación
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Componente estilizado para la imagen con animación
const AnimatedImage = styled("img")(({ animationDuration }) => ({
  width: "80%",
  maxWidth: "500px",
  objectFit: "contain",
  animation: `${pulse} ${animationDuration} infinite`,
}));

const Aplicacion = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginBottom: { xs: "40%", sm: "30%", md: "10%", lg: "5%" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ButtonArrow />
      <GroupButton />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        {/* Primera imagen */}
        <img
          src={Descarga}
          alt="Descarga"
          style={{
            width: "100%",
            maxWidth: "600px",
            objectFit: "contain",
          }}
        />

        <AnimatedImage
          src={NuestraApp}
          alt="NuestraApp"
          animationDuration="2s"
          style={{
            marginTop: "-46%",
          }}
        />
      </div>

      <Typography sx={{ mx: "1rem", marginTop: "-7%" }} variant="h6">
        Descarga nuestra aplicación móvil desde la tienda de tu dispositivo.
        Simplemente presiona el botón correspondiente según el equipo que
        utilices, ya sea para la App Store si tienes un iPhone, o para Google
        Play si usas un dispositivo Android. Una vez instalada, acércate a
        cualquiera de nuestras 11 sucursales, donde nuestro equipo te ayudará a
        activar tu cuenta y te proporcionará un usuario y contraseña
        personalizados.
      </Typography>

      <Grid
        container
        spacing={{ xs: 1, sm: 3, md: 5 }} // Reducir espaciado en pantallas pequeñas
        justifyContent="center"
        alignItems="center"
        sx={{
          marginTop: { xs: "-1rem", sm: "-4rem", md: "-4rem" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: { xs: "-2rem", sm: "2rem", md: "4rem" }, // Reducir espacio en pantallas pequeñas
          }}
        >
          <a
            href="https://apps.apple.com/mx/app/cslh-amovil/id6497485353"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} // Asegura el centrado dentro del enlace
          >
            <Image src={Ios} alt="Redirección a la app de iOS" />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: { xs: "-2rem", sm: "2rem", md: "4rem" }, // Reducir espacio en pantallas pequeñas
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.cajaCSLHweb&hl=es"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} // Asegura el centrado dentro del enlace
          >
            <Image src={Android} alt="Redirección a la app de Android" />
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Aplicacion;
