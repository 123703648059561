import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./assets/components/footer/footer.jsx";
import Ribbon from "../src/assets/components/ribbon/ribbon.jsx";
import Menu from "../src/assets/components/menu/menu";
import Home from "./assets/components/pages/Home.jsx";
import Partners from "./assets/components/pages/partners.jsx";
import About from "./assets/components/pages/about.jsx";
import Product from "./assets/components/pages/Products.jsx";
import Contact from "./assets/components/pages/Contact.jsx";
import Promotions from "./assets/components/pages/promotions.jsx";
import NotFound from "./assets/components/pages/NotFound.jsx";
import Services from "./assets/components/pages/Services/services.jsx";
import DetailsProduct from "./assets/components/pages/DetailsProduct.jsx";
import Cajeros from "./assets/components/pages/Services/cajeros.jsx";
import Remesas from "./assets/components/pages/Services/remesas.jsx";
import Seguros from "./assets/components/pages/Services/seguros.jsx";
import CotizadorCreditos from "./assets/components/pages/cotizadorCredito/cotizadorCredito.jsx";
import CostosComisiones from "./assets/components/CostosComisiones/CostosComisiones.jsx";
import UpdateSystem from "./assets/components/pages/UpdateSystem/Update.jsx";
import AvisoDePrivacidad from "./assets/components/AvisoDeprivacidad/AvisoPrivacidad.jsx";
import EntidadesFinancieras from "../src/assets/components/footer/BuroEntidades.jsx";
import Aplicacion from "../src/assets/components/pages/Services/Aplicacion.jsx"
//SplashScreen
import SplashScreen from "./assets/components/SplashScreen/SplashScreen.jsx";

function App() {
  //Inio de conf Splash screen
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); //Tiempo que dura la pantalla de carga

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, []);

  const currentPath = window.location.pathname;

  if (currentPath !== "/cotizadorCreditos") {
    if (loading) {
      return <SplashScreen />;
    }
  }
  //Fin configuracion splashScreen

  return (
    <Router>
      <div className="App">
        <header className="App-header"></header>
        <Ribbon />
        <Menu />

        <Routes>
          {/* Las rutas deben ser diferentes para cada componente */}
          <Route path="/" element={<Home />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/services" element={<Services />} />
          <Route path="/cajeros" element={<Cajeros />} />
          <Route path="/remesas" element={<Remesas />} />
          <Route path="/seguros" element={<Seguros />} />
          <Route path="/CotizadorCreditos" element={<CotizadorCreditos />} />
          <Route path="/costosComisiones" element={<CostosComisiones />} />
          <Route path="/updateSystem" element={<UpdateSystem />} />
          <Route path="/avisoDePrivacidad" element={<AvisoDePrivacidad />} />
          <Route path="/aplicacionMovil" element={<Aplicacion />} />
          <Route
            path="/entidadesFinancieras"
            element={<EntidadesFinancieras />}
          />
          <Route
            path="/detailsProduct/:type/:id"
            element={<DetailsProduct />}
          />

          {/* Ruta para mostrar el componente NotFound no encontrado */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
