import React, { useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import LocalAtmOutlinedIcon from "@mui/icons-material/AttachMoney";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useLocation } from "react-router-dom";
//change color icon Start
const OrangeArrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <ArrowRightOutlinedIcon sx={{ color: "#FB6823" }} />
  </SvgIcon>
);
//change color icon End

export default function AhorroDetails({ plan }) {
  //Start tab var
  function TabPanel({ value, index, children }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  //End tab var

  //Start var tabs
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab) {
      setValue(parseInt(tab));
    }
  }, [location.search]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //End var tabs

  return (
    <>
      {/*Tab products credit Start*/}
      <Box sx={{ display: "flex", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tab Details Credit"
          orientation="vertical"
        >
          <Tab
            style={{ width: "175px" }}
            icon={<BarChartIcon style={{ fontSize: 32 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: -3,
                mr: 14,
              },
            }}
          />
          {plan.id === "11" ? (
            <Tab
              style={{ width: "175px" }}
              icon={<DoneOutlineIcon style={{ fontSize: 32 }} />}
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  ml: -3,
                  mr: 14,
                },
              }}
            />
          ) : null}
          {plan.id === "11" ? (
            <Tab
              style={{ width: "175px" }}
              icon={<CardGiftcardIcon style={{ fontSize: 32 }} />}
              sx={{
                flexDirection: "row",
                "& .MuiSvgIcon-root": {
                  ml: -3,
                  mr: 14,
                },
              }}
            />
          ) : null}

          <Tab
            style={{ width: "175px" }}
            icon={<LocalAtmOutlinedIcon style={{ fontSize: 43 }} />}
            sx={{
              flexDirection: "row",
              "& .MuiSvgIcon-root": {
                ml: -3,
                mr: 14,
              },
            }}
          />
        </Tabs>

        {/*Tab products credit End*/}

        {/*Contend tabs start*/}
        <Box sx={{ flexGrow: 1 }}>
          <TabPanel value={value} index={0}>
            {/* Contenido del primer tab */}
            <Box>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Descripción
              </Typography>
              <Typography
                component="span"
                variant="body1"
                paragraph
                sx={{ textAlign: "justify" }}
              >
                {plan.description}
              </Typography>

              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Corresponde al tipo de producto{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.TipoProducto}
                    </Box>
                    {plan.TipoProducto2}
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.SaldoMinimo}
                    </Box>{" "}
                    para este producto{" "}
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.MontoApertura}
                    </Box>{" "}
                  </Box>
                </ListItem>
              </List>
              <Box display="flex" justifyContent="center">
                <img
                  src={plan.image}
                  alt={plan.name}
                  style={{ maxWidth: "75%" }}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* Contenido del segundo tab */}
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Beneficios
              </Typography>
              <Typography
                component="span"
                variant="body1"
                paragraph
                sx={{ textAlign: "justify" }}
              >
                {plan.description}
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Beneficio1}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Beneficio2}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Beneficio3}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Beneficio4}
                </ListItem>
              </List>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box sx={{ ml: 4 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Tasas de interés
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    Tasa del{" "}
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      {plan.TasaInteres}
                    </Box>{" "}
                    anual Fija
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Comisiones}
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      GAT Nominal {plan.GatNominal} anual.
                    </Box>{" "}
                    "Es el rendimiento total de la inversión, sin considerar la
                    inflación esperada". Fecha de cálculo: 01-01-2025
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      GAT Real {plan.GatReal} anual.
                    </Box>{" "}
                    "Es el rendimiento total de la inversión una vez descontada
                    la inflación esperada para los próximos 12 meses". Fecha de
                    cálculo: 01-01-2025
                  </Box>
                </ListItem>
              </List>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={3}>
            {/* Contenido del tercer tab */}
            <Box sx={{ ml: 1 }}>
              <Typography
                color={"#062035"}
                variant="h4"
                align="center"
                gutterBottom
              >
                Requisitos
              </Typography>
              <Typography
                component="span"
                variant="body1"
                paragraph
                sx={{ textAlign: "justify" }}
              >
                {plan.description}
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Requisito1}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Requisito2}
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeArrowRightIcon />
                  </ListItemIcon>
                  {plan.Requisito3}
                </ListItem>
              </List>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      {/*End tabs details*/}
    </>
  );
}
