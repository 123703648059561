import {
  Drawer,
  AppBar,
  IconButton,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useRef, useState } from "react";
import logo from "../img/logo.webp";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CalculateIcon from "@mui/icons-material/Calculate";

// Add styles to button Menu
const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    transform: "scale(1.3)",
  },
}));

// Add state Expan when display is small
const ResponsiveDrawer = () => {
  const [open, setOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  //Inicio abrir y cerrar cuando esta el menu en forma de app
  const handleServicesClick = () => {
    setServicesOpen(!servicesOpen);
  };
  //Fin abrir y cerrar cuando esta el menu en forma de app

  const handleProductsClick = () => {
    setProductsOpen(!productsOpen);
  };

  const handleMenuClose = () => {
    setAnchorElProducts(null);
    setProductsOpen(false); // Cierra el submenú cuando se cierra el menú
  };

  // Submenú de "Servicios"
  const [servicesOpen, setServicesOpen] = useState(false);

  //Inicio abrir y cerrar los submenu de servicios
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuServiceClose = () => {
    setMenuAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    // Puedes realizar acciones adicionales al hacer clic en un elemento del menú aquí
    handleMenuServiceClose();
  };
  const menuRef = useRef(null);

  document.addEventListener("mousedown", (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleMenuServiceClose();
    }
  });
  //Fin abrir y cerrar los submenu de servicios

  //Inicio abrir y cerrar los submenu de productos
  const [menuAnchorElProduct, setMenuAnchorElProduct] = useState(null);
  const [isMenuOpenProduct, setIsMenuOpenProduct] = useState(false);

  const handleMenuClickProduct = (event) => {
    setMenuAnchorElProduct(event.currentTarget);
    setIsMenuOpenProduct(!isMenuOpenProduct);
  };

  const handleMenuProductClose = () => {
    setMenuAnchorElProduct(null);
    setIsMenuOpenProduct(false);
  };

  const handleMenuItemClickProduct = () => {
    // Puedes realizar acciones adicionales al hacer clic en un elemento del menú aquí
    handleMenuProductClose();
  };

  const menuProductRef = useRef(null);

  document.addEventListener("mousedown", (event) => {
    if (
      menuProductRef.current &&
      !menuProductRef.current.contains(event.target)
    ) {
      handleMenuProductClose();
    }
  });
  //Fin abrir y cerrar los submenu de productos

  const drawer = (
    <div>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
        <ListItem button component={Link} to="/about">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Conocenos" />
        </ListItem>
        <ListItem button component={Link} to="/partners">
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Socios" />
        </ListItem>
        <ListItem button onClick={handleProductsClick}>
          <ListItemIcon>
            <AddShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Productos" />
          {productsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={productsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/product?category=all"
              onClick={() => handleMenuClose("Todos")}
            >
              <ListItemText primary="Todos" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/product?category=AHORRO"
              onClick={() => handleMenuClose("AHORRO")}
            >
              <ListItemText primary="Ahorros" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/product?category=CREDITO"
              onClick={() => handleMenuClose("CREDITO")}
            >
              <ListItemText primary="Créditos" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/product?category=INVERSION"
              onClick={() => handleMenuClose("INVERSION")}
            >
              <ListItemText primary="Inversiones" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleServicesClick}>
          <ListItemIcon>
            <SupportAgentIcon />
          </ListItemIcon>
          <ListItemText primary="Servicios" />
          {servicesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/cajeros">
              <ListItemText primary="Cajeros Automáticos" />
            </ListItem>
            <ListItem button component={Link} to="/remesas">
              <ListItemText primary="Pago de remesas" />
            </ListItem>
            <ListItem button component={Link} to="/services">
              <ListItemText primary="Pago de servicios" />
            </ListItem>
            <ListItem
              button
              component="a"
              target="_blank"
              href="https://cajasolidarialahuerta.apiof.com.mx/"
            >
              <ListItemText primary="Datos abiertod de ATM" />
            </ListItem>
            <ListItem button component={Link} to="/avisoDePrivacidad">
              <ListItemText primary="Aviso de Privacidad" />
            </ListItem>
            <ListItem button component={Link} to="/aplicacionMovil">
              <ListItemText primary="Descarga nuestra aplicación" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button component={Link} to="/promotions">
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Promociones" />
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contacto" />
        </ListItem>
        <ListItem button component={Link} to="/cotizadorCreditos">
          <ListItemIcon>
            <CalculateIcon />
          </ListItemIcon>
          <ListItemText primary="Simulador de crédito" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        {/* Usamos Grid para crear una rejilla con el logo y los items */}
        <Grid container spacing={1} alignItems="center">
          {/* Aquí ponemos el logo en la esquina izquierda */}
          <Grid item xs={6} sm={2}>
            <Link to="/">
              <img src={logo} alt="Logo Caja Solidaria" className="logo" />
            </Link>
          </Grid>

          {/* Usamos Hidden para ocultar los items en pantallas pequeñas */}
          <Hidden mdDown>
            <Grid item xs={6} sm={10} md={10}>
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12} sm={2}>
                  <StyledButton component={Link} to="/about">
                    Conocenos
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <StyledButton component={Link} to="/partners">
                    Socios
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button onClick={handleMenuClickProduct}>
                    Productos{" "}
                    {isMenuOpenProduct ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMoreOutlinedIcon />
                    )}
                  </Button>
                  <Menu
                    anchorEl={menuAnchorElProduct}
                    open={Boolean(menuAnchorElProduct)}
                    onClose={handleMenuProductClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      component={Link}
                      to="/product?category=all"
                      onClick={() => handleMenuItemClickProduct("Todos")}
                    >
                      Todos
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/product?category=AHORRO"
                      onClick={() => handleMenuItemClickProduct("AHORRO")}
                    >
                      Ahorro
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/product?category=CREDITO"
                      onClick={() => handleMenuItemClickProduct("CREDITO")}
                    >
                      Créditos
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/product?category=INVERSION"
                      onClick={() => handleMenuItemClickProduct("INVERSION")}
                    >
                      Inversiones
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={12} sm={2} ref={menuRef}>
                  <Button
                    onClick={handleMenuClick}
                  >
                    Servicios{" "}
                    {isMenuOpen ? <ExpandLess /> : <ExpandMoreOutlinedIcon />}
                  </Button>
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      component={Link}
                      to="/cajeros"
                      onClick={handleMenuItemClick}
                    >
                      Cajeros Automáticos
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/remesas"
                      onClick={handleMenuItemClick}
                    >
                      Pago de remesas
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/services"
                      onClick={handleMenuItemClick}
                    >
                      Pago de servicios
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href="https://cajasolidarialahuerta.apiof.com.mx/"
                      target="_blank"
                      onClick={handleMenuItemClick}
                    >
                      Datos abiertos de ATM
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/avisoDePrivacidad"
                      onClick={handleMenuItemClick}
                    >
                      Aviso de privacidad
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/aplicacionMovil"
                      onClick={handleMenuItemClick}
                    >
                      Descarga nuestra aplicación
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <StyledButton component={Link} to="/promotions">
                    Promociones
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <StyledButton component={Link} to="/contact">
                    Contacto
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          {/* Usamos Hidden para ocultar el botón en pantallas medianas o grandes */}
          <Hidden mdUp>
            <Grid item xs={6} sm={10}>
              <Grid container justifyContent="flex-end">
                <IconButton
                  sx={{ color: "#DF6027" }}
                  aria-label="Abrir menú"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </AppBar>

      {/* Usamos Hidden para ocultar el Drawer en pantallas medianas o grandes */}
      <Hidden mdUp>
        <Drawer variant="temporary" open={open} onClose={handleDrawerToggle}>
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default ResponsiveDrawer;
